<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="用户昵称">
        <el-input
          size="mini"
          v-model="formInline.nickname"
          placeholder="请输入用户昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="答题状态">
        <el-select
          v-model="formInline.answerStatus"
          size="mini"
          placeholder="请选择"
        >
          <el-option size="mini" label="未答题" :value="0"> </el-option>
          <el-option size="mini" label="答题正确" :value="1"> </el-option>
          <el-option size="mini" label="答题错误" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="答题时间">
        <el-date-picker
          v-model="formInline.time"
          size="mini"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
        <el-button size="mini" type="primary" @click="goBack">返回</el-button>
      </el-form-item>
      <el-form-item>
        <download-excel
          :before-generate="beforeGenerate"
          :data="excelData"
          :fields="headerData"
          worksheet="答题记录"
          name="答题记录"
        >
          <el-button size="mini"
            >导出<i class="el-icon-download el-icon--right"></i
          ></el-button>
        </download-excel>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'left'
      }"
      :cell-style="{ textAlign: 'left' }"
    >
      <el-table-column prop="date" label="昵称 I 手机">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.avatar"
            :preview-src-list="[row.avatar]"
          >
          </el-image>
          <div>
            <div>昵称：{{ row.nickname }}</div>
            <div>手机：{{ row.phone }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="所属门店">
        <template>{{ name }}</template>
      </el-table-column>
      <el-table-column prop="topicName" label="专题活动名称"> </el-table-column>
      <el-table-column label="统计">
        <template slot-scope="{ row }">
          <div class="zzz">
            <div>
              答题状态：<span>{{ state[row.answerStatus] }}</span>
            </div>
            <div>
              总题目数：<span>{{ row.totalQuestionCount }}</span>
            </div>
            <div>
              答对题目数：<span>{{ row.trueQuestionCount }}</span>
            </div>
            <div>
              奖励金额：<span>{{ row.rewardAmount || 0 }}</span
              >元
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="answerTime" label="答题时间"> </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserTopicPageByCurrentStore } from '../../../api/user'
export default {
  data() {
    return {
      excelData: [],
      headerData: {
        昵称: {
          field: 'nickname',
          callback: (value) => {
            return value
          }
        },
        手机号: {
          field: 'phone',
          callback: (value) => {
            return value
          }
        },
        所属门店: {
          field: 'name',
          callback: (value) => {
            return value
          }
        },
        专题活动: {
          field: 'topicName',
          callback: (value) => {
            return value
          }
        },
        所属门店: {
          field: 'name',
          callback: (value) => {
            return value
          }
        },
        答题状态: {
          field: 'answerStatus',
          callback: (value) => {
            return value == 0 ? '未答题' : value == 1 ? '答题正确' : '答题错误'
          }
        },
        总题目数: {
          field: 'totalQuestionCount',
          callback: (value) => {
            return value
          }
        },
        答对题目数: {
          field: 'trueQuestionCount',
          callback: (value) => {
            return value
          }
        },
        奖励金额: {
          field: 'rewardAmount',
          callback: (value) => {
            return value || 0
          }
        },
        答题时间: {
          field: 'answerTime',
          callback: (value) => {
            return value
          }
        }
      },
      state: ['未答题', '答题正确', '答题错误'],
      name: localStorage.getItem('nickname'),
      tableData: [],
      total: 0,
      size: 20,
      current: 1,
      formInline: {
        nickname: '',
        answerStatus: '',
        time: []
      },
      id: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getUserTopicPageByCurrentStore()
  },
  methods: {
    async beforeGenerate() {
      this.excelData = []
      const { data } = await getUserTopicPageByCurrentStore({
        pageSize: this.total,
        pageNum: 1,
        nickname: this.formInline.nickname,
        answerStatus: this.formInline.answerStatus,
        answerBeginTime: this.formInline.time.length
          ? this.formInline.time[0] + ' 00:00:00'
          : '',
        answerEndTime: this.formInline.time.length
          ? this.formInline.time[1] + ' 23:59:59'
          : '',
        storeTopicId: this.id,
        listType: 1
      })
      console.log(data)
      data.list.forEach((res) => {
        res.name = this.name
      })
      this.excelData = data.list
    },
    goBack() {
      this.$router.go(-1)
    },
    search() {
      this.current = 1
      this.getUserTopicPageByCurrentStore()
    },
    async getUserTopicPageByCurrentStore() {
      const { data } = await getUserTopicPageByCurrentStore({
        pageSize: this.size,
        pageNum: this.current,
        nickname: this.formInline.nickname,
        answerStatus: this.formInline.answerStatus,
        answerBeginTime: this.formInline.time.length
          ? this.formInline.time[0] + ' 00:00:00'
          : '',
        answerEndTime: this.formInline.time.length
          ? this.formInline.time[1] + ' 23:59:59'
          : '',
        storeTopicId: this.id,
        listType: 1
      })
      console.log(data)
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getUserTopicPageByCurrentStore()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getUserTopicPageByCurrentStore()
    }
  }
}
</script>

<style lang="scss" scoped>
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.zzz {
  span {
    color: #ff6f00;
  }
}
</style>
